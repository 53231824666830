import * as React from "react"
// import lottie from "lottie-web"
import Lottie from "lottie-react-web"
import { Container, Row, Col } from "react-bootstrap"
import logo from "../images/loyall-brandmark.json"
import PropTypes from "prop-types"

const Introduction = ({ title, subtitle }) => {
  // React.useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     document.querySelector("#loyall-brandmark").innerHTML = ""

  //     lottie.loadAnimation({
  //       container: document.querySelector("#loyall-brandmark"),
  //       animationData: logo,
  //       renderer: "svg", // "canvas", "html"
  //       loop: true, // boolean
  //       autoplay: true, // boolean
  //     })
  //   }
  // })

  return (
    <Container className="component">
      <Row>
        <Col xs={12} lg={{ span: 10, offset: 1 }}>
          {/* <div className="text-center">
          <div id="loyall-brandmark" className="pb-4"></div>
        </div> */}
          <div className="text-center">
            <div id="loyall-brandmark">
              <Lottie
                options={{
                  animationData: logo,
                  renderer: "svg",
                  loop: true,
                  autoplay: true,
                }}
              />
            </div>
          </div>

          <h1 className="text-center">{title}</h1>
          <p className="subtitle text-center">{subtitle}</p>
        </Col>
      </Row>
    </Container>
  )
}

Introduction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Introduction
